import styled from "styled-components";

export const SocialLink = styled.a`
  margin-top: 11px;
  text-decoration: none;
  color: #fff;
  font-size: 50px;
  cursor: pointer;

  @media screen and (max-width: 762px) {
    font-size: 30px;
  }
`;
