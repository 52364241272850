import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
`;

export const RoadmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5rem 5rem 5rem;
  font-size: 24px;
  text-align: center;
  background-color: #8f250c;
  color: white;
  position: relative;

  @media screen and (max-width: 1059px) {
    flex-direction: column;
  }

  @media screen and (max-width: 525px) {
    height: auto;
  }
`;

export const FriendsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5rem 5rem 5rem;
  font-size: 24px;
  text-align: center;
  background-color: #8f250c;
  color: white;
  position: relative;

  @media screen and (max-width: 1059px) {
    flex-direction: column;
  }

  @media screen and (max-width: 525px) {
    height: auto;
  }
`;

export const RoadmapImg = styled.img`
  width: 100vw;
  height: auto;
  margin-bottom: 50px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 30px;
  }

  @media screen and (max-width: 762px) {
    font-size: 25px;
  }
`;

export const Header = styled.h1`
  font-size: 80px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 60px;
  }

  @media screen and (max-width: 762px) {
    font-size: 40px;
    top: 20px;
  }
`;
