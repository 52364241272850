import React from "react";

import {
  TokenomicsContainer,
  Description,
  Title,
  Header,
  TokenomicsImg,
  SubTitle,
  CardContainer,
} from "./Tokenomics.styles";
import TokenomicsLogo from "../../../../assets/tokenomics.webp";
import Card from "./Card";

const Tokenomics = () => {
  const items = [
    {
      id: 1,
      title: "Liquidity",
      desc: "Burned",
    },
    {
      id: 2,
      title: "Freeze/Mint",
      desc: "Revoked",
    },
    {
      id: 3,
      title: "Tax",
      desc: "0/0",
    },
  ];

  return (
    <TokenomicsContainer id="tokenomics">
      <Header>Tokenomics</Header>
      <TokenomicsImg alt="tokenomics logo" src={TokenomicsLogo} />
      <Description>
        <br />
        <SubTitle>Total Circulation Supply</SubTitle>
        <Title>500.000.000.00</Title>
        <Title>No Team Tokens</Title>
        <br />
      </Description>
      <CardContainer>
        {items.map((item) => (
          <Card key={item.id} {...item} />
        ))}
      </CardContainer>
    </TokenomicsContainer>
  );
};

export default Tokenomics;
