import React from "react";

import {
  RoadmapContainer,
  Description,
  FriendsContainer,
  Title,
  Header,
  RoadmapImg,
} from "./Roadmap.styles";
import RoadmapLogo from "../../../../assets/roadmap.webp";
import FriendsLogo from "../../../../assets/friends.webp";

const Roadmap = () => {
  return (
    <div>
      <FriendsContainer id="friends">
        <RoadmapImg alt="friends logo" src={FriendsLogo} />
        <Header>Meet my friends</Header>
        <Description>
          <br />
          <Title>
            pepe signh loves cats and dogs and the battle between the dogs and
            cats are over.
          </Title>
          <br />
        </Description>
      </FriendsContainer>
      <RoadmapContainer id="roadmap">
        <RoadmapImg alt="roadmap logo" src={RoadmapLogo} />
        <Header>Roadmap</Header>
        <Description>
          <br />
          <Title>Phase 1: Launch</Title>
          <Title>Phase 2: Buy</Title>
          <Title>Phase 3: HODL</Title>
          <Title>Phase 4: Go to Moon Together</Title>
          <br />
        </Description>
      </RoadmapContainer>
    </div>
  );
};

export default Roadmap;
