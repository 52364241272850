import React from "react";
import { CardContainer } from "./Card.styles";
import { SubTitle, Title } from "./Tokenomics.styles";

const Card = ({ title, desc }) => {
  return (
    <CardContainer>
      <Title>{title}</Title>
      <SubTitle>{desc}</SubTitle>
    </CardContainer>
  );
};

export default Card;
