import React from "react";
import {
  DisclaimerContainer,
  Header,
  SubTitle,
  Title,
} from "./Disclaimer.styles";

const Disclaimer = () => {
  return (
    <DisclaimerContainer id="disclaimer">
      <Header>Disclaimer</Header>
      <Title>
        pepe signh has no association with the Pepe the Frog. This token is
        created with a twist of indian culture. $SIGNH is a meme coin with no
        intrinsic value or expectation of financial return. There is no formal
        team or roadmap. The coin is completely useless and for entertainment
        purposes only.
      </Title>
      <br />
      <Title>
        pepe signh do not own the rights to the music used on this website.
      </Title>
      <br />
      <SubTitle>&copy; All rights reserved, 2024.</SubTitle>
    </DisclaimerContainer>
  );
};

export default Disclaimer;
