import React from "react";
import { enqueueSnackbar } from "notistack";

import {
  HeroContainer,
  Description,
  Title,
  HeroImg,
  ContractAddressContainer,
} from "./Hero.styles";
import HeroLogo from "../../../../assets/hero.webp";
import PanjabiMusic from "../../../../assets/panjabi.mp3";
import ReactAudioPlayer from "react-audio-player";

const Hero = () => {
  const onContractAddressClick = () => {
    navigator.clipboard.writeText(
      "7DRmsQrZ6jMSoDkZwCTaZCdDRoi6JXNYckncZpmMVwUQ"
    );
    enqueueSnackbar(
      "Copied contact address: 7DRmsQrZ6jMSoDkZwCTaZCdDRoi6JXNYckncZpmMVwUQ",
      {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }
    );
  };

  return (
    <HeroContainer id="about">
      <Description>
        <Title>
          Pepe Singh, the token that's as spicy as a vindaloo and as dank as
          your favorite meme!
        </Title>
        <p>The most memorable meme of all time.</p>
        <p>But as an Indian.</p>
        <p>Contract Address:</p>
        <ContractAddressContainer onClick={onContractAddressClick}>
          <p>7DRmsQrZ6jMSoDkZwCTaZCdDRoi6JXNYckncZpmMVwUQ</p>
        </ContractAddressContainer>
        <p>Panjabi MC - Mundian To Bach Ke (Beware Of The Boys)</p>
        <ReactAudioPlayer src={PanjabiMusic} autoPlay loop controls />
      </Description>
      <HeroImg alt="hero logo" src={HeroLogo} />
    </HeroContainer>
  );
};

export default Hero;
