import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
`;

export const TokenomicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  font-size: 24px;
  text-align: center;
  background-color: #ca5310;
  color: white;
  position: relative;

  @media screen and (max-width: 1059px) {
    flex-direction: column;
  }

  @media screen and (max-width: 762px) {
    padding: 5rem;
  }

  @media screen and (max-width: 525px) {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const TokenomicsImg = styled.img`
  @keyframes leftright {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(20px);
    }
  }

  width: auto;
  height: auto;
  animation-name: leftright;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 0.2s;

  @media screen and (max-width: 1221px) {
    width: 400px;
    height: 400px;
  }

  @media screen and (max-width: 1059px) {
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 430px) {
    margin-top: 40px;
  }

  @media screen and (max-width: 380px) {
    width: 250px;
    height: 250px;
    margin-top: 60px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 30px;
  }

  @media screen and (max-width: 762px) {
    font-size: 25px;
  }
`;

export const Header = styled.h1`
  font-size: 80px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 60px;
  }

  @media screen and (max-width: 762px) {
    font-size: 40px;
    top: 20px;
  }
`;

export const SubTitle = styled.h1`
  font-size: 30px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 20px;
  }

  @media screen and (max-width: 762px) {
    font-size: 20px;
  }
`;
