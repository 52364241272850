import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  border: 1.5px solid white;
  min-width: 300px;
  border-radius: 5px;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
`;

export const Subtitle = styled.p`
  font-size: 10px;
  font-weight: 400;
`;
