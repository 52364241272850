import styled from "styled-components";

export const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-color: #691e06;
  padding: 5rem;
  position: relative;
  padding-bottom: 5rem;
  padding-top: 30px;
`;

export const Header = styled.h1`
  font-size: 80px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 60px;
  }

  @media screen and (max-width: 762px) {
    font-size: 40px;
    top: 20px;
  }
`;

export const SubTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 20px;
  }

  @media screen and (max-width: 762px) {
    font-size: 15px;
  }
`;
