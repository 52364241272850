import React, { useState } from "react";
import { BsTwitterX } from "react-icons/bs";
import { PiTelegramLogoBold } from "react-icons/pi";

import PepeSignhLogo from "../../assets/logo.webp";

import "./Navbar.css";
import { SocialLink } from "./Navbar.styles";

const Navbar = () => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const handleMenuClick = () => {
    setIsMenuClicked(!isMenuClicked);
  };

  return (
    <nav>
      <a id="home" href="/">
        <img src={PepeSignhLogo} alt="nav logo" height={50} width={70} />
        <p>pepe signh</p>
      </a>
      <div>
        <ul
          id="navbar"
          className={isMenuClicked ? "#navbar active" : "#navbar"}
        >
          <li onClick={handleMenuClick}>
            <a href="#about">About</a>
          </li>
          <li onClick={handleMenuClick}>
            <a href="#tokenomics">Tokenomics</a>
          </li>
          <li onClick={handleMenuClick}>
            <a href="#friends">Friends</a>
          </li>
          <li onClick={handleMenuClick}>
            <a href="#roadmap">Roadmap</a>
          </li>
          <li onClick={handleMenuClick}>
            <a href="#disclaimer">Disclaimer</a>
          </li>
          <li onClick={handleMenuClick}>
            <SocialLink
              href="https://twitter.com/pepesignh"
              rel="noreferrer noopenner"
              target="_blank"
            >
              <BsTwitterX />
            </SocialLink>
          </li>
          <li onClick={handleMenuClick}>
            <SocialLink
              href="https://t.me/pepesignh"
              rel="noreferrer noopenner"
              target="_blank"
            >
              <PiTelegramLogoBold />
            </SocialLink>
          </li>
        </ul>
      </div>
      <div id="mobile" onClick={handleMenuClick}>
        <i
          id="bar"
          className={isMenuClicked ? "fas fa-times" : "fas fa-bars"}
        ></i>
      </div>
    </nav>
  );
};

export default Navbar;
