import styled from "styled-components";

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem 10rem;
  font-size: 24px;
  text-align: center;
  background-color: #fbba72;
  color: black;
  z-index: 0;

  @media screen and (max-width: 895px) {
    flex-direction: column-reverse;
    padding: 2rem;
  }

  @media screen and (max-width: 525px) {
    height: auto;
    padding-bottom: 30px;
  }
`;

export const HeroImg = styled.img`
  @keyframes updown {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(20px);
    }
  }

  width: auto;
  height: auto;
  animation-name: updown;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 0.5s;

  @media screen and (max-width: 895px) {
    padding-bottom: 40px;
    height: 500px;
  }
`;

export const Description = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 20px;
  max-width: 700px;

  @media screen and (max-width: 1048px) {
    max-width: 500px;
  }

  @media screen and (max-width: 540px) {
    max-width: 450px;
  }

  @media screen and (max-width: 470px) {
    max-width: 400px;
  }

  @media screen and (max-width: 420px) {
    max-width: 350px;
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 30px;
  }

  @media screen and (max-width: 762px) {
    font-size: 25px;
  }
`;

export const ContractAddressContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid black;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: black;
  cursor: pointer;
  gap: 5px;
  font-size: 23px;
  overflow-y: auto;
`;
